/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useState } from "react";
import { graphql } from "gatsby";
import tw, { css, theme } from "twin.macro";
import { useWindowDimensions } from "~hooks";
import { EASING_CUBIC } from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { Button, Go, LineSwipe } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as Wave6A } from "~assets/svg/header-6-shape-a.svg";
import { ReactComponent as ArrowUp } from "~assets/svg/arrow-up.svg";

/** ============================================================================
 * @component
 * A text area with expandable questions and answers.
 */
const FaqSection = ({
  data: {
    backToTop,
    scrollTarget,
    withWave,
    fullPage,
    backgroundColor,
    headingFontColor,
    fontColor,
    subheading,
    heading,
    button,
    buttonPosition,
    faqQuestions
  }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [expandedIndex, setExpandedIndex] = useState(null);

  const { screen } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // variables

  const buttonDiv = (
    <div css={[tw`w-full relative mt-6 md:mt-16`]}>
      <Go to={button?.url}>
        <Button _css={[tw`w-56 h-16 relative block`]} text={button?.text} />
      </Go>
    </div>
  );

  // ---------------------------------------------------------------------------
  // methods

  const scrollToTop = () => {
    if (typeof window === `undefined`) {
      return;
    }

    const scroll = document.getElementById(scrollTarget);
    window.scrollTo({
      top: scroll ? scroll.getBoundingClientRect().top + window.scrollY : 0,
      behavior: `smooth`
    });
  };

  // ---------------------------------------------------------------------------
  // render

  const renderQuestions = (
    <>
      {faqQuestions.map(
        ({ question, answer, answerTable, answerButton }, questionIndex) => {
          const key = `question-section-${questionIndex}`;
          const expanded = expandedIndex === questionIndex;

          let buttonColor = fontColor?.hex || theme`colors.black`;

          if (expanded) {
            buttonColor = fontColor?.hex || theme`colors.turqouise`;
          }

          return (
            <div key={key} css={[tw`w-full relative block`]}>
              <div
                css={[
                  css`
                    //
                    height: 3px;
                  `,
                  tw`w-full absolute top-0 right-0 left-0`
                ]}
              >
                <div
                  css={[
                    css`
                      transition: 0.3s transform ${EASING_CUBIC};
                      transform: scaleX(${expanded ? 1 : 0});
                      transform-origin: 0 50%;

                      height: 3px;
                      background: ${buttonColor};
                    `,
                    tw`w-1/2 absolute top-0 bottom-0 left-0`
                  ]}
                />

                <div
                  css={[
                    css`
                      background: ${buttonColor};
                      height: 1px;
                      top: 1px;
                    `,
                    tw`w-full absolute right-0 left-0`
                  ]}
                />
              </div>

              <button
                type="button"
                css={[
                  css`
                    transition: 0.3s ease color;

                    color: ${buttonColor};
                  `,
                  tw`w-full relative block pt-4 md:pt-6 pb-10 md:pb-4 lg:pb-8 text-left`
                ]}
                onClick={() =>
                  setExpandedIndex(expanded ? null : questionIndex)
                }
              >
                <div
                  css={[tw`w-full relative flex items-start justify-between`]}
                >
                  <T.Head _css={[tw`w-4/5 font-bold`]} font="5">
                    {question}
                  </T.Head>

                  <T.Head
                    _css={[
                      css`
                        font-size: 48px !important;

                        ${MEDIA_QUERIES.desktop} {
                          font-size: 24px !important;
                        }
                      `,
                      tw`w-1/5 relative -mt-4 md:-mt-2 text-right`
                    ]}
                    font="3"
                  >
                    {expanded ? `-` : `+`}
                  </T.Head>
                </div>
              </button>

              <div
                css={[
                  css`
                    transition: padding 0.3s ${EASING_CUBIC};

                    padding: ${expanded ? `0.5rem 0 3rem` : `0`};
                    color: ${fontColor?.hex || `#000000`};
                  `
                ]}
              >
                {expanded && (
                  <>
                    <T.Body _css={[tw`w-5/6 relative whitespace-pre-wrap`]}>
                      {answer}
                    </T.Body>

                    {answerTable?.length > 0 && (
                      <div
                        css={[
                          css`
                            display: grid;
                            grid-template-columns:
                              repeat(
                                ${answerTable?.length - 1},
                                minmax(0, fit-content(10%))
                              )
                              1fr;
                          `,
                          tw`mt-4`
                        ]}
                      >
                        {answerTable.map((column, columnIndex) =>
                          column?.rows?.map((cell, cellIndex) => (
                            <div
                              css={[
                                css`
                                  grid-column: span 1 / span 1;
                                  grid-column-start: ${columnIndex + 1};
                                  grid-row-start: ${cellIndex + 1};
                                `
                              ]}
                            >
                              <T.Body
                                _css={[
                                  css`
                                    padding: 0.75rem 1rem 1rem 0rem;
                                  `,
                                  tw`whitespace-pre-wrap`,
                                  columnIndex === 0 && tw`font-bold`
                                ]}
                                font="1"
                              >
                                {cell}
                              </T.Body>
                            </div>
                          ))
                        )}
                      </div>
                    )}

                    {answerButton?.text && (
                      <Go to={answerButton?.url}>
                        <Button
                          _css={[
                            css`
                              color: ${fontColor?.hex || getColor(`black`)};
                            `,
                            tw`w-2/5 h-16 relative block border mt-12`
                          ]}
                          text={answerButton?.text}
                        />
                      </Go>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        }
      )}
    </>
  );

  return (
    <>
      <div
        id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
        css={[
          css`
            background-color: ${backgroundColor?.hex || `#f5f6fa`};
          `,
          tw`w-full relative overflow-hidden flex flex-col items-center justify-center pt-20 md:pt-24 lg:pt-32 pb-20 md:pb-16 lg:pb-24 text-blue-deep`,
          fullPage && tw`pt-52 md:pt-56 lg:pt-64`
        ]}
      >
        {backToTop && (
          <div css={[tw`absolute block bottom-8 right-0 z-30 border`]}>
            <button
              type="button"
              css={[
                tw`w-28 h-16 z-30 bg-black`,
                css`
                  svg {
                    margin: auto;
                    transition: 0.3s ease transform;
                  }

                  ${MEDIA_QUERIES.hoverable} {
                    &:hover {
                      svg {
                        transform: translate3d(-1.5rem, 0, 0);
                      }
                    }
                  }
                `
              ]}
              onClick={scrollToTop}
              label="back-to-top"
            >
              <ArrowUp
                fill="white"
                css={[
                  css`
                    display: absolute;
                    height: 50%;
                  `
                ]}
              />
            </button>
          </div>
        )}
        {withWave && (
          <div css={[tw`w-full h-full absolute top-0 right-0 bottom-0 left-0`]}>
            <Wave6A />
          </div>
        )}

        <Grid _css={[tw`z-20`]}>
          <header
            css={[
              css`
                color: ${headingFontColor?.hex || `#002762`};
              `,
              tw`col-span-12 relative flex items-center md:mb-16 lg:mb-20`
            ]}
          >
            <T.Head font="4">{subheading}</T.Head>

            <div css={[tw`w-32 relative block ml-5`]}>
              <LineSwipe
                color={headingFontColor?.hex === `#ffffff` && `#ffffff`}
                delay={500}
                duration={2000}
              />
            </div>
          </header>

          <div
            css={[
              css`
                color: ${headingFontColor?.hex || `#002762`};
              `,
              tw`col-span-12 md:col-span-5 lg:col-span-4 mt-10 md:mt-0 mb-12 md:mb-0 md:pr-8 lg:pr-0 relative font-bold`,
              fullPage && tw`col-span-12 md:col-span-8 lg:col-span-7`
            ]}
          >
            <T.Head
              font={screen === `xs` || screen === `sm` || fullPage ? `1` : `2`}
            >
              {heading}
            </T.Head>

            {button && buttonPosition === `left` && buttonDiv}

            {/* todo: re-enable on client request */}
            {/* {fullPage && (
              <div css={[tw`w-full relative flex mt-16 space-x-12`]}>
                <div>
                  <Button
                    _css={[
                      css`
                        width: 24rem;
                      `,
                      tw`h-14 relative border`
                    ]}
                    arrow="down"
                    text="By topic"
                  />
                </div>

                <div>
                  <Button
                    _css={[tw`w-52 h-14 relative border`]}
                    arrow="reset"
                    text="Reset filters"
                  />
                </div>
              </div>
            )} */}
          </div>

          {!fullPage && (
            <div
              css={[
                tw`col-span-12 md:col-span-6 md:col-start-6 relative`,
                fullPage && tw`md:col-span-11 col-start-1 mt-24 `
              ]}
            >
              {renderQuestions}

              {button &&
                (buttonPosition === `right` || !buttonPosition) &&
                buttonDiv}
            </div>
          )}
        </Grid>
      </div>

      {fullPage && (
        <div
          css={[
            css`
              background-color: ${getColor(`white`)};
            `,
            tw`w-full relative overflow-hidden flex flex-col items-center justify-center pt-32 pb-20 md:pb-16 lg:pb-24 text-blue-deep`
          ]}
        >
          <Grid _css={[tw`z-20`]}>
            <div
              css={[
                tw`col-span-12 md:col-span-6 md:col-start-6 relative`,
                fullPage && tw`md:col-span-11 col-start-1`
              ]}
            >
              {renderQuestions}
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

export default FaqSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment FaqSectionFragment on SanityFaqSection {
    _type

    backgroundColor {
      hex
      title
    }

    headingFontColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    withWave
    fullPage
    backToTop
    scrollTarget

    subheading
    heading

    button {
      text
      url
    }

    buttonPosition

    faqQuestions {
      question
      answer
      answerTable {
        rows
      }
      answerButton {
        text
        url
      }

      tag
    }
  }
`;
